import React from "react";
// import { Outlet } from "react-router-dom";
import RemovalAbout from "./RemovalAbout";
import RemovalContact from "./RemovalContact";
import RemovalFooter from "./RemovalFooter";
import RemovalGallerySection from "./RemovalGallerySection";
import RemovalInterview from "./RemovalInterview";
import RemovalLandingPage from "./RemovalLandingPage";

import RemovalTestimonial from "./RemovalTestimonial";

const index = () => {
  return (
    <>
      <RemovalLandingPage />
      {/* <Outlet /> */}
      <RemovalAbout />
      <RemovalGallerySection />
      
      <RemovalTestimonial />
      <RemovalInterview />
      <RemovalContact />
      <RemovalFooter />
    </>
  );
};

export default index;
