import React from "react";
import { Helmet } from "react-helmet";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./components/Home";
import About from "./components/Home/About";
import GallerySection from "./components/Home/GallerySection";
import Interview from "./components/Home/Interview";
import Contact from "./components/Home/Contact";
import LandingPage from "./components/Home/LandingPage";
import Skills from "./components/Home/Skills";
import Testimonial from "./components/Home/Testimonial";
import Footer from "./components/Home/Footer";
import WhatsAppButton from "./components/Home/w-button";
import TattooRemoval from "./components/TattooRemoval";
import RemovalAbout from "./components/TattooRemoval/RemovalAbout";
import RemovalGallerySection from "./components/TattooRemoval/RemovalGallerySection";
import RemovalTestimonial from "./components/TattooRemoval/RemovalTestimonial";
import RemovalInterview from "./components/TattooRemoval/RemovalInterview";
import RemovalContact from "./components/TattooRemoval/RemovalContact";
import RemovalFooter from "./components/TattooRemoval/RemovalFooter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          { path: "/", element: <LandingPage /> },
          { path: "about", element: <About /> },
          { path: "gallery", element: <GallerySection /> },
          { path: "skill", element: <Skills /> },
          { path: "quotes", element: <Testimonial /> },
          { path: "inception", element: <Interview /> },
          { path: "contacts", element: <Contact /> },
          { path: "footer", element: <Footer /> },
        ],
      },
      {
        path: "/tattoo-removal",
        element: <TattooRemoval />,
        children: [
          { path: "", element: <LandingPage /> },
          { path: "TattooRemovalAbout", element: <RemovalAbout /> },
          { path: "removalgallery", element: <RemovalGallerySection /> },
          { path: "removalquotes", element: <RemovalTestimonial /> },
          { path: "removalinception", element: <RemovalInterview /> },
          { path: "removalcontacts", element: <RemovalContact /> },
          { path: "removalfooter", element: <RemovalFooter /> },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <WhatsAppButton />
    </>
  );
}

export default App;
